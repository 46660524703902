@font-face {
  font-family: psr;
  src: url("https://nkpl.namishkumar.in/psr.ttf");
}
@font-face {
  font-family: psb;
  src: url("https://nkpl.namishkumar.in/Product_Sans_Bold.ttf");
}

body {
  margin: 8px;
    padding: 0;
  font-family: psr;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
h1 {
  font-family: psb;
}

html {
  height: 100%;
}
button {
  padding: 13px;
  background-color: black;
  color: white;
  border: none;
  width: 10em;
  border-radius: 5px;
  font-family: psr;
  transition: 0.3s;
}
button:hover {
  padding: 13px;
  background-color: rgb(30, 30, 30);
  color: white;
  border: none;
  width: 10em;
  border-radius: 5px;
  font-family: psr;
  transition: 0.3s;
}
button:active {
  padding: 13px;
  background-color: rgb(84, 84, 84);
  color: white;
  border: none;
  width: 10em;
  border-radius: 5px;
  font-family: psr;
  transition: 0.3s;
}
input {
  width: 300px;
  padding: 15px;
  outline: none;
  border: none;
  border-color: rgb(84, 84, 84);
  border-style: solid;
  border-width: 1px;
  border-radius: 5px;
  font-size: 22px;
  margin-left: 8px;
  transition: 0.3s;
}
input:active {
  width: 300px;
  padding: 15px;
  outline: none;
  border: none;
  border-radius: 5px;
  border-color: rgb(30,30,30);
  border-style: solid;
  border-width: 1px;
  font-size: 22px;
  margin-left: 8px;
  transition: 0.3s;
}
input:focus {
  width: 300px;
  padding: 15px;
  outline: none;
  border-radius: 360px;
  border: none;
  border-color: black;
  border-style: solid;
  border-width: 1px;
  font-size: 22px;
  margin-left: 8px;
  transition: 0.3s;
}